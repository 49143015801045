<template>
  <v-list-item-content>
    <v-row
      class="align-center"
      dense
    >
      <v-col
        cols="12"
        md="3"
      >
        <v-text-field
          v-model.number="quantity"
          class="d-flex align-center"
          label="Qtd."
          placeholder="000"
          outlined
          rounded
          dense
          hide-details
          @click.stop
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
      >
        <v-text-field
          :value="manualUnitaryValue"
          class="d-flex align-center"
          label="Valor UN"
          placeholder="0,00"
          outlined
          rounded
          dense
          hide-details
          @input="setManualUnitaryValue"
          @click.stop
        >
          <template v-slot:append>
            <span style="line-height: 25px">
              {{ formattedUnitaryPrice }}
            </span>
          </template>
        </v-text-field>
      </v-col>
      <v-col
        cols="12"
        md="3"
      >
        <span>Total: {{ formattedTotalPrice }}</span>
      </v-col>
    </v-row>
  </v-list-item-content>
</template>

<script>
export default {
  props: {
    row: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    product: {
      type: Object,
      required: true,
    },
    print: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      quantity: '100',
      manualUnitaryValue: '',
    }
  },
  computed: {
    isManualUnitaryValue() {
      return this.manualUnitaryValue !== ''
    },
    unitaryPrice() {
      return this.calculatePrice()
    },
    formattedUnitaryPrice() {
      return this.formatCurrency(this.unitaryPrice, 3)
    },
    totalPrice() {
      if (this.isManualUnitaryValue) {
        return this.manualUnitaryValueNumber * parseInt(this.quantity)
      }

      const price = this.calculatePrice()
      return price * parseInt(this.quantity)
    },
    formattedTotalPrice() {
      return this.formatCurrency(this.totalPrice, 2)
    },
    manualUnitaryValueNumber() {
      return this.unformatCurrency(this.manualUnitaryValue, 3)
    },
    dynamincUnitaryPrice() {
      if (this.isManualUnitaryValue) {
        return this.manualUnitaryValueNumber
      }
      return this.unitaryPrice
    },
  },
  watch: {
    manualUnitaryValue() {
      this.onChange()
    },
    quantity() {
      this.onChange()
    },
    print() {
      this.onChange()
    },
  },
  mounted() {
    
    this.onChange()
  },

  methods: {
    calculatePrice() {
      const quantity = parseInt(this.quantity)

      if (quantity < 1) {
        return 0
      }

      // Remove Vue Observable fature from data that we'll use
      const print = JSON.parse(JSON.stringify(this.print))
      const product = JSON.parse(JSON.stringify(this.product))

      if (print.entityType !== 'PRINT' && print.entityType !== 'TABLE') {
        return 0
      }

      // Require at least one principal supplier setup for this product with price cost defined
      const productTableCost = product.vendor.cost_tables[0]

      // check if product has fixed cost for any range of selling quantity
      const productHasFixedCost = productTableCost.is_fixed === 1

      // defined the base operating cost value for each product for any quantity
      const baseOperatingCost = parseFloat(this.$store.state.fixedValue)
      // find the fractional value of base operating cost that will be applied to the product cost
      const fractionalBaseOperatingCost = baseOperatingCost / quantity

      // Find the profit margin table that will be applied to the product cost
      const factorRow = product.factor.rows.find(
        (row) => row.count_start <= quantity && row.count_end >= quantity
      )
      if(!factorRow){
        return 0
      }

      // Set the profit margin percentage
      const profitMarginPercentage = factorRow.percentage
      // Set the profile margin multiplier value
      const profitMarginMultiplier = profitMarginPercentage / 100 + 1 // ex: (80 / 100 ) + 1 = 1.8

      let productCost = 0
      let printCost = 0
      let tableCost = 0

      // if the product cost has range of prices based on quantity
      if (!productHasFixedCost) {
        const productCostRange = product.vendor.cost_tables[0].rows.find(
          (row) => row.from <= quantity && row.to >= quantity
        )
        productCost = productCostRange.cost
      }
      // Assuming the price is the same no matter the quantity value
      else {
        const productFixedCost = product.vendor.cost_tables[0].rows[0].cost
        productCost = productFixedCost
      }

      if (print.entityType === 'PRINT') {
        // Find the engraving price inside of range quantity
        const printCostRange = print.rows.find(
          (row) => row.count_start <= quantity && row.count_end >= quantity
        )

        if(!printCostRange){
          return 0
        }

        // set the printCost with value found
        printCost = printCostRange.amount
      } else if (print.entityType === 'TABLE') {
        const productWithEngravingCostTable = print.rows
        // Find the product with engraving price inside of range quantity for the selected principal supplier
        const productEngravingCostRange = productWithEngravingCostTable.find(
          (row) => row.from <= quantity && row.to >= quantity
        )

        if(!productEngravingCostRange){
          return 0
        }

        // set the printCost with value found
        tableCost = productEngravingCostRange.cost

        // set the product cost as 0 because the in the step the tableCost variable alreay has the product cost with engraving cost provider by the supplier
        productCost = 0
      }

      // Sum the unitary cost of product and engraving
      const productWithEngravingCost = productCost + printCost + tableCost

      // Add the base operation cost
      const productWithOperationCost =
        productWithEngravingCost + fractionalBaseOperatingCost

      // Multiple by profit margin value
      const unitaryPrice = productWithOperationCost * profitMarginMultiplier

      // Return the final product unitary price value
      return unitaryPrice
    },
    setManualUnitaryValue(newValue) {
      if (newValue == '') {
        this.manualUnitaryValue = ''
        return
      }

      const value = this.unformatCurrency(newValue, 3)

      if (value === 0) {
        this.manualUnitaryValue = ''
        return
      }

      const formatted = this.formatCurrency(value, 3)

      this.manualUnitaryValue = formatted
    },
    onChange() {
      const unitary_value = this.dynamincUnitaryPrice
      this.$emit('onChange', {
        index: this.index,
        manual_unitary_value: this.isManualUnitaryValue,
        quantity: this.quantity,
        unitary_value: unitary_value.toFixed(3),
      })
    },
  },
}
</script>
